import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  convertThumbnailToPictureProps,
} from '@tager/web-modules';

import useCurrentPage from '@/hooks/useCurrentPage';
import { DescriptionOfTechnologySectionCdpPageType } from '@/typings/model';
import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { getHtmlString, splitStringOnSpace } from '@/utils/common';
import FeedBack from '@/components/FeebBack';

function DescriptionOfTechnology() {
  const page = useCurrentPage<DescriptionOfTechnologySectionCdpPageType>();

  const pageFields = page?.templateFields;

  if (!pageFields) return null;

  return (
    <Section>
      <ContentContainer>
        <Content>
          <InfoBlock>
            <InfoTitle>{pageFields.imageBlockTitle}</InfoTitle>
            <InfoItemList>
              {splitStringOnSpace(pageFields.imageBlockList)?.map(
                (item, index) => (
                  <InfoItem key={index}>
                    <InfoItemText>{item}</InfoItemText>
                  </InfoItem>
                )
              )}
            </InfoItemList>
          </InfoBlock>
          {pageFields.imageBlockImage ? <PictureColumn>
            <PictureWrapper>
              <StyledPicture
                tabletSmall={convertThumbnailToPictureImage(
                  pageFields.imageBlockImage.desktop
                )}
                mobileSmall={convertThumbnailToPictureImage(
                  pageFields.imageBlockImage.mobile
                )}
              />
            </PictureWrapper>
          </PictureColumn> : null}
        </Content>
        <WrapperFeedback>
          <FeedBack>
            <FeedBackText
              dangerouslySetInnerHTML={{
                __html: getHtmlString(pageFields.imageBlockCta ?? ''),
              }}
            />
          </FeedBack>
        </WrapperFeedback>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 60px 0 120px;
  background: #f3f8f9;

  ${media.tabletSmall(css`
    padding-top: 28px;
    padding-bottom: 90px;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const WrapperFeedback = styled.div`
  margin-top: 60px;

  & .feedback-content {
    padding: 39px 20px 38px;
    ${media.tabletSmall(css`
      padding-bottom: 53px;
    `)}
  }

  ${media.tabletSmall(css`
    margin-top: 0;
  `)}

  ${media.mobile(css`
    margin-top: 30px;

    .feedback-content {
      padding: 39px 20px 38px;
    }
  `)}
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    flex-direction: column;
  `)}
`;

const FeedBackText = styled.div`
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  font-size: 30px;
  line-height: 130%;
  text-align: center;

  span {
    display: inline-block;
    position: relative;
    transition: all 250ms ease;

    &:hover:before {
      opacity: 0;
    }

    &:hover {
      color ${colors.pink};
    }

    &:before {
      display: block;
      position: absolute;
      content: '';
      bottom: -2px;
      width: 100%;
      height: 2px;
      background: linear-gradient(152.63deg, #7e4aa1 1.38%, #4e55d1 88.96%);
      transition: opacity 250ms ease;
    }

    background: linear-gradient(152.63deg, #7e4aa1 1.38%, #4e55d1 88.96%);
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(0, 0, 0, 0);
  }

  ${media.tabletSmall(css`
    font-size: 26px;
  `)}

  ${media.mobile(css`
    font-size: 22px;
    line-height: 130%;
  `)}
`;

const PictureColumn = styled.div`
  flex: 0 0 50%;
  position: relative;
  //min-width: 0px;

  padding: 0 30px;

  ${media.tabletSmall(css`
    padding: 0;
  `)}
`;

const PictureWrapper = styled.div`
  @media (max-width: 1440px) {
    margin-right: -120px;
  }

  ${media.tabletSmall(css`
    position: initial;
    margin-right: 0;
  `)}
`;

const StyledPicture = styled(Picture)`
  position: relative;
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: initial;
    height: 100%;

    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);

    ${media.tabletSmall(css`
      max-width: 100%;
      height: initial;
      margin: 33px 0 61px;
    `)}
  }
`;

const InfoBlock = styled.div`
  flex: 0 0 50%;
  min-width: 0px;
  margin-top: 60px;
`;
const InfoTitle = styled.div`
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
  color: ${colors.dark};

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
    line-height: 130%;
  `)}
`;
const InfoItemList = styled.ul`
  margin-top: 14px;
  display: flex;
  flex-direction: column;

  ${media.tabletSmall(css`
    margin-top: 17px;
  `)}
`;

const InfoItem = styled.li`
  position: relative;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  padding-left: 30px;
  padding-right: 44px;

  ${media.tabletSmall(css`
    padding-left: 37px;
    margin-top: 12px;

    &:last-child {
      max-width: 653px;
    }
  `)}
  ${media.mobile(css`
    font-size: 18px;
    line-height: 150%;
  `)}
  &:first-child {
    margin-top: 0;
  }

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${colors.dark};
    top: 16px;
    left: 15px;
  }
`;
const InfoItemText = styled.span`
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

export default DescriptionOfTechnology;
