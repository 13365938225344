import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { QuestionSectionCdpPageType } from '@/typings/model';
import { ReactComponent as QuestionIcon } from '@/assets/svg/question-icon.svg';
import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

function QuestionSection() {
  const page = useCurrentPage<QuestionSectionCdpPageType>();

  const pageFields = page?.templateFields;

  if (!pageFields) return null;

  return (
    <Section>
      <Content>
        <ContentContainer>
          <ContentInner>
            <QuestionWrapper>
              <QuestionBlock>
                <WrapperQuestion>
                  <QuestionIconWrapper>
                    <QuestionIcon />
                  </QuestionIconWrapper>
                  <QuestionTitle>{pageFields.questionQuestion}</QuestionTitle>
                </WrapperQuestion>
              </QuestionBlock>
            </QuestionWrapper>
            <AnswerBlock>
              <AnswerTitle>{pageFields.questionAnswer}</AnswerTitle>
            </AnswerBlock>
          </ContentInner>
        </ContentContainer>
      </Content>
    </Section>
  );
}

const Section = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 120px 0 124.5px;
  background: ${colors.white};

  ${media.tabletSmall(css`
    padding: 103px 0 88.5px;
  `)}

  ${media.mobile(css`
    padding: 60px 0 64.5px;
  `)}
`;

const Content = styled.div`
  position: relative;

  z-index: 10;
`;

const ContentInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tabletSmall(css`
    flex-direction: column;
    justify-content: initial;
  `)}
`;

const QuestionWrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
    position: absolute;
    content: '';
    display: block;
    height: 100%;
    width: 100vw;
    background: #f3f8f9;
    top: 0;
    left: -100vw;
  }

  ${media.tabletSmall(css`
    &:before {
      display: none;
    }
  `)}
`;

const QuestionBlock = styled.div`
  position: relative;
  width: 100%;
  background: #f3f8f9;
  padding: 40px 81px 40px 0;
  clip-path: polygon(0 0, 74% 0, 88% 51%, 74% 100%, 0 100%, 0% 50%);

  ${media.tabletSmall(css`
    padding: 0;
    clip-path: none;
    background: none;
  `)}
`;

const WrapperQuestion = styled.div`
  max-width: 359px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.tabletSmall(css`
    max-width: initial;
    padding: 0;
  `)}
`;

const QuestionTitle = styled.span`
  max-width: 280px;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;

  ${media.tabletSmall(css`
    max-width: initial;
    line-height: 150%;
  `)}
`;
const QuestionIconWrapper = styled.div`
  margin-right: 32px;
`;

const AnswerBlock = styled.div`
  width: 100%;
  display: block;
  flex: 0 0 54%;
`;

const AnswerTitle = styled.span`
  display: block;
  font-size: 29px;
  line-height: 130%;

  ${media.tabletSmall(css`
    max-width: 651px;
    margin-top: 34px;
    font-size: 30px;
    line-height: 130%;
    padding-left: 76px;
  `)}

  ${media.mobile(css`
    margin-top: 20px;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
  `)}
`;

export default QuestionSection;
