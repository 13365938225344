import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { createMediaMixin } from '@tager/web-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { TechnologiesSectionType } from '@/typings/model';
import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { ReactComponent as CheckedIcon } from '@/assets/svg/checkbox.svg';
import { colors } from '@/constants/theme';
import { Title, Designation } from '@/modules/Home/components/common';
import { media } from '@/utils/mixin';

function TechnologiesSection() {
  const page = useCurrentPage<TechnologiesSectionType>();
  const pageFields = page?.templateFields;

  function getExpertiseItem(item: string, index: number) {
    return (
      <ExpertiseItem key={index}>
        <CheckedIcon />
        <ExpertiseItemText>{item}</ExpertiseItemText>
      </ExpertiseItem>
    );
  }

  if (!pageFields) return null;
  return (
    <Section>
      <ContentContainer>
        <Inner>
          <Content>
            <PictureColumn>
              <PictureWrapper>
                <StyledPicture
                  tabletSmall={convertThumbnailToPictureImage(
                    pageFields.technologiesImage.desktop
                  )}
                  mobileSmall={convertThumbnailToPictureImage(
                    pageFields.technologiesImage.mobile
                  )}
                />
              </PictureWrapper>
            </PictureColumn>
            <TechnologiesInfo>
              <Designation>{pageFields.technologiesName}</Designation>
              <Title>{pageFields.technologiesTitle}</Title>
              <Text>{pageFields.technologiesText}</Text>
              <ExpertiseBlockWrapper>
                <ExpertiseBlock>
                  <ExpertiseInnerWrapper>
                    <ExpertiseTitle>
                      {pageFields.technologiesListTitle}
                    </ExpertiseTitle>
                    <ExpertiseList>
                      {pageFields.technologiesListItems &&
                        pageFields.technologiesListItems
                          .split('\n')
                          .map(getExpertiseItem)}
                    </ExpertiseList>
                  </ExpertiseInnerWrapper>
                </ExpertiseBlock>
              </ExpertiseBlockWrapper>
            </TechnologiesInfo>
          </Content>
        </Inner>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  background: ${colors.white};
  overflow: hidden;
`;

const Inner = styled.div`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -30px;

  ${media.tabletSmall(css`
    flex-direction: column;
  `)}
`;

const PictureColumn = styled.div`
  max-width: 100%;
  //max-height: 805px;
  position: relative;
  min-width: 0px;
  flex: 0 0 50%;
  padding: 0 30px;

  ${createMediaMixin({ max: 1376 })(css``)}

  ${media.mobile(css`
    padding: 0;
    max-height: 100%;
  `)}
`;

const PictureWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 52px;
  left: 30px;
  bottom: 0;
  overflow: hidden;

  @media (max-width: 1440px) {
    left: -90px;
    min-height: 904px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }

  ${media.tabletSmall(css`
    position: initial;
    min-height: 100%;
    margin: 0 -20px;
  `)}
`;

const ExpertiseBlockWrapper = styled.div`
  padding-right: 26px;

  ${media.laptopLarge(css`
    padding: 34px 26px 0 0;
  `)}

  ${media.tabletSmall(css`
    padding: 34px 0;
  `)}

  ${media.mobile(css`
    margin-top: 42px;
    padding: 0;
  `)}
`;

const StyledPicture = styled(Picture)`
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: 795px;
    height: 100%;

    ${media.tabletSmall(css`
      max-height: 560px;
      object-fit: cover;
    `)}

    ${media.mobile(css`
      height: initial;
      object-fit: initial;
    `)}
  }

  ${createMediaMixin({ max: 1376 })(css`
    height: 100%;
  `)}
`;
const TechnologiesInfo = styled.div`
  padding: 0 10px;
  //padding: 2px 0 64px 10px;
  min-width: 0px;
  flex: 0 0 50%;

  ${media.tabletSmall(css`
    margin-top: 17px;
    padding: 0 30px;
  `)}
`;

const Text = styled.p`
  margin-top: 42px;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  margin-right: 26px;
  transform: translateY(5px);

  ${media.tabletSmall(css`
    margin-right: 0;
  `)}

  ${media.mobile(css`
    margin-top: 21px;
    font-size: 18px;
    line-height: 150%;
    margin-right: 0;
    transform: none;
  `)}
`;

const ExpertiseBlock = styled.div`
  margin-top: 14px;
  width: 100%;
  padding-top: 33px;

  ${media.laptopLarge(css`
    padding-top: 0;
  `)}

  ${media.tabletSmall(css`
    padding-top: 0;
  `)} //background: ${colors.gray100};
  //padding: 72px 38px 40px 58px;
`;

const ExpertiseInnerWrapper = styled.div`
  padding: 40px 38px 40px 58px;

  ${media.laptopLarge(css`
    padding: 40px 38px 40px 58px;
  `)}

  ${media.mobile(css`
    padding: 40px 20px;
  `)}
  background: ${colors.gray100};

}


`;

const ExpertiseTitle = styled.span`
  display: block;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
`;

const ExpertiseList = styled.ul`
  display: block;
  font-size: 20px;
  line-height: 130%;
`;

const ExpertiseItem = styled.li`
  margin-top: 22px;

  svg {
    margin-right: 20px;
  }
`;

const ExpertiseItemText = styled.span`
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
`;

export default TechnologiesSection;
