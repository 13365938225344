import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';

import useCurrentPage from '@/hooks/useCurrentPage';
import { HomePageType } from '@/typings/model';
import Hero, { HeroTitle, HeroText, HeroButton } from '@/components/Hero';
import SuggestionsSection from '@/modules/Home/components/SuggestionsSection';
import TechnologiesSection from '@/modules/Home/components/Technologies';
import FeedBackFirst from '@/modules/Home/components/FeedBackFirst';
import Services from '@/modules/Home/components/Services';
import FeedBackSecond from '@/modules/Home/components/FeedBackSecond';
import useForm from '@/hooks/useForm';
import useAnimation from '@/hooks/useAnimation';
import { media } from '@/utils/mixin';

import { InnerMainContent } from '../common.styles';

function Home() {
  const page = useCurrentPage<HomePageType>();
  const [openForm] = useForm();
  const pageFields = page?.templateFields;

  useAnimation({
    animationElement: 'inner-main-content',
    targetElement: 'hero-component',
  });

  if (!pageFields) return null;

  return (
    <Wrapper>
      <Hero
        videoSource={pageFields.heroVideoBackground}
        backgroundImage={pageFields.heroImageBackground}
        backgroundImageMobile={pageFields.heroImageBackgroundMobile}
      >
        <WrapperContent>
          <HeroTitle
            dangerouslySetInnerHTML={{ __html: pageFields.heroTitle ?? '' }}
          />
          {<HeroText>{nl2br(pageFields.heroText)}</HeroText>}

          <HeroButton onClick={() => openForm()}>
            {pageFields.heroButtonLabel}
          </HeroButton>
        </WrapperContent>
      </Hero>
      <InnerMainContent id="inner-main-content">
        <SuggestionsSection />
        <TechnologiesSection />
        <FeedBackFirst />
        <Services />
        <FeedBackSecond />
      </InnerMainContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WrapperContent = styled.div`
  max-width: 692px;
  width: 100%;

  ${media.tabletSmall(css`
    max-width: 674px;
  `)}
`;

export default Home;
