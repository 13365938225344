import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import LogoGallery from '@/components/LogoGallery';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ClientsCdpPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

function Clients() {
  const page = useCurrentPage<ClientsCdpPageType>();
  const pageFields = page?.templateFields;

  if (!pageFields) return null;

  return (
    <Section>
      <ContentContainer>
        <LogoGallery
          items={pageFields.logosItems}
          title={pageFields.logosTitle ?? ''}
        />
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding: 122px 0 91px;
  width: 100%;
  background: ${colors.white};

  ${media.tabletSmall(css`
    padding-top: 95px;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

export default Clients;
