import React from 'react';
import styled, { css } from 'styled-components';

import { SmallContentContainer } from '@/components/ContentContainer';
import { FeedBackColor } from '@/components/FeebBack';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FeedBackCdpSectionType } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function FeedBack() {
  const page = useCurrentPage<FeedBackCdpSectionType>();
  const pageFields = page?.templateFields;
  if (!pageFields) return null;

  return (
    <Section>
      <SmallContentContainer>
        <FeedBackColor
          description={pageFields.ctaTitle}
          title={pageFields.ctaText}
          buttonTitle={pageFields.ctaButton}
        />
      </SmallContentContainer>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  overflow: hidden;
  background: ${colors.gradient};

  .feedback-color-component {
    padding: 58px 0 70px;

    button {
      margin-top: -27px;
      margin-right: 103px;

      ${media.tabletSmall(css`
        margin-right: 0;
      `)}

      ${media.mobile(css`
        margin: 40px 0 0 0;
      `)}
    }
  }
`;

export default FeedBack;
