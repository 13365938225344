import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import StyledButton from '@/components/StyledButton';
import { colors } from '@/constants/theme';

export const HeroTitle = styled.h1<{ color?: string }>`
  position: relative;
  font-weight: 600;
  font-size: 60px;
  line-height: 110%;
  color: ${({ color }) => (color ? color : 'black')};
  margin: 0;
  color: ${colors.white};
  ${media.tabletSmall(css`
    font-size: 54px;
  `)}

  ${media.mobile(css`
    font-size: 36px;
    line-height: 130%;
  `)};
  ${media.mobileSmall(css`
    font-size: 28px;
  `)};
`;

export const HeroText = styled.p<{ color?: string }>`
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: ${({ color }) => (color ? color : 'black')};
  margin-top: 23px;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

export const HeroButton = styled(StyledButton)`
  margin-top: 42px;
  box-shadow: 0px 10px 20px 0px #00000026;

  ${media.tabletSmall(css`
    margin-top: 37px;
  `)}
`;
