import React from 'react';
import styled, { css } from 'styled-components';

import { StringFieldType } from '@/typings/common';
import StyledButton from '@/components/StyledButton';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import useForm from '@/hooks/useForm';

interface Props {
  description?: StringFieldType;
  title: StringFieldType;
  buttonTitle: StringFieldType;
  center?: boolean;
}

export function FeedBackColor({
  buttonTitle,
  description,
  title,
  center = false,
}: Props) {
  const [openForm] = useForm();
  if (!buttonTitle && !title) return null;
  return (
    <Component className="feedback-color-component">
      <Content>
        {description ? <Description>{description}</Description> : null}
        <InnerWrapper className="feedback-color-wrapper" center={center}>
          <Title
            className="feedback-color-title"
            dangerouslySetInnerHTML={{ __html: title ?? '' }}
          />
          <StyledButton
            className="feedback-color-btn"
            onClick={openForm}
            styleType={'primary'}
            isBgColor={true}
          >
            {buttonTitle}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  padding: 58px 0;

  ${media.mobile(css`
    padding: 40px 0;
  `)}
`;

const InnerWrapper = styled.div<{ center: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: ${(props) => (props.center ? '780px' : '100%')};
  margin: 0 auto;

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
  button {
    box-shadow: 0px 10px 20px 0px #00000026;
  }
`;
const Content = styled.div`
  position: relative;
  flex-direction: column;

  & button {
    ${media.mobile(css`
      margin: 58px 0 0 0;
    `)}
  }
`;

const Description = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.white};
  margin-bottom: 20px;
`;

const Title = styled.span`
  max-width: 775px;
  margin-right: 68px;
  display: block;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;
  color: ${colors.white};

  ${media.tabletSmall(css`
    font-size: 26px;
    margin-right: 0;
  `)}
  br {
    ${media.mobile(css`
      display: none;
    `)}
  }

  ${media.mobile(css`
    font-size: 22px;
    line-height: 130%;
    flex: none;
    margin-right: 0;
    text-align: start;
  `)}
`;
