import styled from 'styled-components';

export const InnerMainContent = styled.div`
  width: 100%;
  z-index: 100;
  margin-top: 720px;

  & section {
    z-index: 100;
  }
`;
