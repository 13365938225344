import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { getHtmlString } from '@/utils/common';
import { media } from '@/utils/mixin';

function SuggestionsSection() {
  const page = useCurrentPage();
  const pageFields = page?.templateFields;

  function getSuggestionsItem(item: string, index: number) {
    const match = getHtmlString(item, false);
    return (
      <SuggestionsItem
        key={index}
        styleSelectedWord={index}
        dangerouslySetInnerHTML={{ __html: match }}
        isGradientColor={!!(index % 2)}
      />
    );
  }

  if (!pageFields || !pageFields.keyPhrases) return null;
  return (
    <Section>
      <ContentContainer>
        <SuggestionsList>
          {pageFields.keyPhrases
            .split('\n')
            .filter(Boolean)
            .map(getSuggestionsItem)}
        </SuggestionsList>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  background: ${colors.white};
  padding: 113px 0;

  ${media.tabletSmall(css`
    padding: 108px 0 91px;
  `)}

  ${media.mobile(css`
    padding: 40px 0;
  `)}
`;

const SuggestionsList = styled.ul`
  list-style-type: circle;

  ${media.tabletSmall(css`
    margin-top: -17px;
  `)}
`;

const SuggestionsItem = styled.li<{
  styleSelectedWord: number;
  isGradientColor: boolean;
}>`
  display: block;
  position: relative;
  padding-left: 48px;
  font-weight: normal;
  font-size: 32px;
  line-height: 170%;
  color: ${colors.black};

  span {
    white-space: pre;
    background: ${({ isGradientColor }) =>
      isGradientColor
        ? '#784CA8'
        : 'linear-gradient(128.73deg, #EC6A81 5.54%, #964589 48.55%, #4856D8 96.33%)'};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:before {
    position: absolute;
    content: '';
    left: 22px;
    top: 23px;
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${colors.black};

    ${media.tabletSmall(css`
      left: 17px;
      top: 13px;
    `)}

    ${media.mobile(css`
      top: 12px;
    `)}
  }

  ${media.tabletSmall(css`
    font-size: 26px;
    line-height: 130%;
    padding-left: 40px;

    &:first-child {
      margin-top: 0;
    }

    margin-top: 20px;
  `)}

  ${media.mobile(css`
    font-size: 22px;
  `)}
`;

export default SuggestionsSection;
