import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { StringFieldType } from '@/typings/common';
import { media } from '@/utils/mixin';
import useForm from '@/hooks/useForm';

interface Props {
  title?: StringFieldType;
  linkText?: StringFieldType;
  children?: React.ReactNode;
}

function FeedBack({ linkText, title, children }: Props) {
  const [openForm] = useForm();

  return (
    <Component>
      <Content className="feedback-content">
        {title ? <Title>{title}</Title> : null}
        {linkText ? (
          <StyledLink onClick={openForm}>{linkText}</StyledLink>
        ) : null}
        {children}
      </Content>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  padding: 39px 0 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 2px solid;
  border-image-slice: 1;
  border-image-source: ${colors.gradient};

  ${media.mobile(css`
    padding: 40px 20px;
  `)}
`;

const Title = styled.span`
  display: block;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;

  ${media.tabletSmall(css`
    font-size: 26px;
  `)}

  ${media.mobile(css`
    text-align: center;
    font-size: 22px;
    line-height: 130%;
  `)}
`;

const StyledLink = styled.div`
  position: relative;
  display: block;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover:before {
    opacity: 0;
  }

  &:hover {
    color: ${colors.pink};
  }

  &:before {
    display: block;
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 2px;
    background: ${colors.gradient};
    transition: opacity 150ms ease-in-out;
  }

  background: ${colors.gradient};
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);

  ${media.tabletSmall(css`
    font-size: 26px;
  `)}

  ${media.mobile(css`
    margin-top: 0;
    font-size: 22px;
    line-height: 130%;
  `)}
`;

export default FeedBack;
