import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import {
  convertThumbnailToPictureImage,
  convertThumbnailToPictureProps,
} from '@tager/web-modules';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { KeysCdpPageType } from '@/typings/model';
import { breakpoints, colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { ReactComponent as LinkIcon } from '@/assets/svg/link.svg';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

SwiperCore.use([Pagination]);

const colorsGradient = [
  `linear-gradient(88.97deg, #E26682 0.96%, #AF5087 99.21%)`,
  `linear-gradient(90.57deg, #A84D88 0.43%, #7E4BA2 99.45%)`,
  `linear-gradient(90deg, #7F4BA1 0%, #5354CD 100%)`,
] as const;

function KeyCases() {
  const page = useCurrentPage<KeysCdpPageType>();

  const [isMountedSwiper, setIsMountedSwiper] = useState<boolean>(false);

  useEffect(() => {
    setIsMountedSwiper(true);
  }, []);

  const pageFields = page?.templateFields;

  if (!pageFields) return null;
  return (
    <Section>
      <ContentContainer>
        <Content>
          <ContentTitle>{pageFields.casesTitle}</ContentTitle>
          <KeysBlock>
            {isMountedSwiper ? (
              <Swiper
                slidesPerView={3}
                slidesPerGroup={1}
                speed={700}
                spaceBetween={25}
                pagination={{
                  type: 'bullets',
                  clickable: true,
                }}
                breakpoints={{
                  280: {
                    spaceBetween: 8,
                    allowTouchMove: true,
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                  },
                  [breakpoints.tabletSmall]: {
                    spaceBetween: 25,
                    autoplay: false,
                    slidesPerView: 3,
                    allowTouchMove: true,
                    pagination: false,
                  },
                }}
              >
                {pageFields.casesItems
                  ? pageFields.casesItems.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <KeysCard bgColor={colorsGradient[index]}>
                            <Logo>
                              <PictureWrapper>
                                <Picture
                                  {...convertThumbnailToPictureImage(item.logo)}
                                />
                              </PictureWrapper>
                            </Logo>

                            <SubKeysList>
                              {item.items
                                ? item.items.map((subItem, index) => (
                                    <KeysItem key={index}>
                                      <KeysItemPercent>
                                        {subItem.value}
                                      </KeysItemPercent>
                                      <KeysItemTitle>
                                        {subItem.param}
                                      </KeysItemTitle>
                                    </KeysItem>
                                  ))
                                : null}
                            </SubKeysList>
                            <WrapperLinkIcon to={item.url} target={'_blank'}>
                              <WrapperIcon>
                                <LinkIcon />
                              </WrapperIcon>
                            </WrapperLinkIcon>
                          </KeysCard>
                        </SwiperSlide>
                      );
                    })
                  : null}
              </Swiper>
            ) : null}
          </KeysBlock>
        </Content>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  padding: 120px 0 0 0;
  background: ${colors.white};

  ${media.tabletSmall(css`
    padding-top: 90px;
  `)}

  ${media.mobile(css`
    padding-top: 60px;
  `)}
`;

const WrapperLinkIcon = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
`;

const WrapperIcon = styled.div`
  position: absolute;
  right: 33px;
  bottom: 37px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  position: relative;
  //min-height: 29px;
`;

const PictureWrapper = styled.div`
  position: absolute;
`;

const ContentTitle = styled.div`
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
  text-align: center;

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
    line-height: 130%;
  `)}
`;
const KeysBlock = styled.div`
  margin-top: 40px;

  .swiper-pagination {
    display: none;
    ${media.mobile(css`
      display: block !important;
    `)}
  }

  .swiper-container {
    ${media.mobile(css`
      padding-bottom: 48px;
    `)}
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination-bullet {
    background: rgba(70, 76, 94);
    margin: 0 6px;
  }

  .swiper-pagination-bullet-active {
    background: ${colors.pink};
  }
`;

const KeysItem = styled.div`
  color: ${colors.white};
  margin-top: 38px;
`;

const KeysCard = styled.div<{ bgColor: string }>`
  position: relative;
  height: 100%;
  padding: 60px 13px 60px 40px;
  background: ${({ bgColor }) => bgColor};
  min-height: 597px;
  transition: all 250ms ease;

  svg {
    transition: transform 250ms ease;

    &:hover {
      transform: translateY(-10px);
    }
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
    opacity: 0;
    transition: opacity 250ms ease;
    cursor: pointer;
    z-index: 1;
  }

  &:hover {
    //opacity: 0.95;

    svg {
      transition: transform 250ms ease;
      transform: translateY(-10px);
    }
  }

  &:hover:after {
    opacity: 1;
  }

  &:first-child {
    // ${KeysItem} {
    //   &:last-child {
    //     margin-top: 5px;
    //   }
    // }
  }

  ${media.tabletSmall(css`
    padding: 47px 13px 60px 19px;
  `)}
`;

const SubKeysList = styled.div`
  margin-top: 69px;
`;

const KeysItemTitle = styled.span`
  display: block;
  font-size: 20px;
  line-height: 150%;
  padding-right: 30px;
  margin-top: -7px;

  ${media.tabletSmall(css`
    padding-right: 0;
  `)}
`;
const KeysItemPercent = styled.span`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 150%;
`;

export default KeyCases;
