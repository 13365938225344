import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import FeedBack from '@/components/FeebBack';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FeedBackSectionType } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

function FeedBackFirst() {
  const page = useCurrentPage<FeedBackSectionType>();
  const pageFields = page?.templateFields;

  if (!pageFields) return null;

  return (
    <Section>
      <ContentContainer>
        <FeedBack
          title={pageFields.ctaFirstTitle}
          linkText={pageFields.ctaFirstButtonLabel}
        />
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding-top: 64px;
  margin-top: -5px;
  background: ${colors.white};

  ${media.laptopLarge(css`
    margin-top: -6px;
  `)}

  width: 100%;

  ${media.tabletSmall(css`
    padding-top: 46px;
  `)}

  ${media.mobile(css`
    padding-top: 60px;
  `)}
`;

export default FeedBackFirst;
