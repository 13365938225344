import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ResourcesCdpPageType } from '@/typings/model';
import { colors } from '@/constants/theme';
import { ReactComponent as LinkIcon } from '@/assets/svg/link.svg';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

function Resources() {
  const page = useCurrentPage<ResourcesCdpPageType>();
  const pageFields = page?.templateFields;
  if (!pageFields) return null;
  return (
    <Section>
      <ContentContainer>
        <Content>
          <Title>{pageFields.solutionTitle}</Title>
          <SubTitle>{pageFields.solutionText}</SubTitle>
          <CardList>
            {pageFields.solutionItems
              ? pageFields.solutionItems.map((card, index) => {
                  return (
                    <Card key={index}>
                      <CardTitle>{card.text}</CardTitle>
                      <CardLink to={card.link ?? '#'} target={'_blank'}>
                        <WrapperIcon>
                          <LinkIcon />
                        </WrapperIcon>
                      </CardLink>
                    </Card>
                  );
                })
              : null}
          </CardList>
        </Content>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.div`
  width: 100%;
  overflow: hidden;
  background: ${colors.white};
`;

const Content = styled.div``;

const WrapperIcon = styled.div`
  position: absolute;
  right: 18px;
  bottom: 17px;
`;

const Title = styled.span`
  display: block;
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
  text-align: center;

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;
const SubTitle = styled.span`
  display: block;
  margin-top: 20px;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
`;
const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const CardTitle = styled.span`
  max-width: 302px;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  transition: color 250ms ease;

  ${media.tabletSmall(css`
    font-size: 20px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    line-height: 130%;
  `)}
`;
const Card = styled.div`
  position: relative;
  min-height: 220px;
  width: calc(33.33% - 20px);
  padding: 57px 37px 57px 33px;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: ${colors.gradient};

  svg {
    path {
      stroke: ${colors.purple};
    }

    transition: transform 250ms ease;
  }

  &:hover {
    ${CardTitle} {
      color: ${colors.pink};
    }

    svg {
      transform: translateY(-10px);
    }

    svg path {
      stroke: ${colors.pink};
    }
  }

  ${media.tabletSmall(css`
    min-height: 220px;
    padding: 20px 13px 20px 20px;
  `)}

  ${media.mobile(css`
    margin-top: 20px;
    width: 100%;
    min-height: 152px;

    &:first-child {
      margin-top: 0;
    }
  `)}
`;

const CardLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export default Resources;
