import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { WorkRulesCdpPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

function WorkRules() {
  const page = useCurrentPage<WorkRulesCdpPageType>();
  const pageFields = page?.templateFields;
  if (!pageFields) return null;

  const strategyInfoTitle = pageFields.textImageTitle ?? '';

  return (
    <Section>
      <ContentContainer>
        <Content>
          <TextWrapper>
            <Title>{pageFields.textFirstLine}</Title>
            <Text>{pageFields.textSecondLine}</Text>
          </TextWrapper>
          <StrategyBlock>
            <StrategyInfoWrapper>
              <StrategyInfoTitle
                dangerouslySetInnerHTML={{
                  __html: strategyInfoTitle.replace('*', '<span>*</span>'),
                }}
              />
              <StrategyInfoText>{pageFields.textImageText}</StrategyInfoText>
            </StrategyInfoWrapper>
            {pageFields.textImageImage ? <PictureColumn>
              <PictureWrapper>
                <StyledPicture
                  tabletSmall={convertThumbnailToPictureImage(
                    pageFields.textImageImage.desktop
                  )}
                  mobileSmall={convertThumbnailToPictureImage(
                    pageFields.textImageImage.mobile
                  )}
                />
              </PictureWrapper>
            </PictureColumn> : null}
          </StrategyBlock>
        </Content>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  padding: 120px 0 61px;
  background: ${colors.white};

  ${media.tabletSmall(css`
    padding: 90px 0 50px;
  `)}

  ${media.mobile(css`
    padding: 60px 0 0 0;
  `)}
`;

const Content = styled.div``;

const TextWrapper = styled.div`
  max-width: 990px;
  width: 100%;
`;

const Title = styled.p`
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;
  ${media.tabletSmall(css`
    font-size: 26px;
  `)}

  ${media.mobile(css`
    font-size: 22px;
    line-height: 130%;
  `)}
`;
const Text = styled.p`
  max-width: 997px;
  width: 100%;
  margin-top: 64px;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  padding-right: 65px;

  ${media.tabletSmall(css`
    margin-top: 42px;
    padding-right: 20px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    line-height: 150%;
    padding-right: 0;
  `)}
`;

const StrategyBlock = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    flex-direction: column;
  `)}
`;

const StrategyInfoWrapper = styled.div`
  margin-top: 133px;
  flex: 0 0 50%;
  padding-right: 96px;

  ${media.tabletSmall(css`
    padding: 0;
    flex: none;
    margin-top: 92px;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const StrategyInfoTitle = styled.span`
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;

  & > span {
    color: rgb(201, 109, 149);
  }

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;
const StrategyInfoText = styled.p`
  margin-top: 40px;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;

  ${media.mobile(css`
    font-size: 18px;
    line-height: 150%;
  `)}
`;

const PictureColumn = styled.div`
  margin-top: 39px;
  position: relative;
  min-width: 0px;
  flex: 0 0 50%;
  padding: 0 30px;
  left: -22px;

  ${media.tabletSmall(css`
    padding: 0;
    margin: 0;
    left: 0;
  `)}
`;

const StyledPicture = styled(Picture)`
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: initial;
    height: 100%;
    margin-top: 20px;

    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);

    ${media.tabletSmall(css`
      max-width: 100%;
      height: initial;
      margin: 41px 0;
    `)}
  }
`;

const PictureWrapper = styled.div`
  @media (max-width: 1440px) {
    margin-right: -120px;
  }

  ${media.tabletSmall(css`
    position: initial;
    margin-right: 0;
  `)}
`;

export default WorkRules;
