import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import LogoGallery from '@/components/LogoGallery';
import useCurrentPage from '@/hooks/useCurrentPage';
import { IntegrationsCdpPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

function Integrations() {
  const page = useCurrentPage<IntegrationsCdpPageType>();
  const pageFields = page?.templateFields;

  if (!pageFields) return null;

  return (
    <Section>
      <ContentContainer>
        <LogoGallery
          items={pageFields.integrationsItems}
          title={pageFields.integrationsTitle ?? ''}
        />
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding: 121px 0 83px;
  width: 100%;
  background: ${colors.white};

  ${media.tabletSmall(css`
    padding: 90px 0 83px;
  `)}

  ${media.mobile(css`
    padding: 60px 0;

    & .logo-item:last-child {
      display: none;
    }
  `)}
`;

export default Integrations;
