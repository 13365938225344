import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { createMediaMixin } from '@tager/web-components';

import ContentContainer from '@/components/ContentContainer';
import { Title, Designation } from '@/modules/Home/components/common';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ServicesSectionType } from '@/typings/model';
import Picture from '@/components/Picture';
import { splitStringOnSpace } from '@/utils/common';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function Services() {
  const page = useCurrentPage<ServicesSectionType>();
  const pageFields = page?.templateFields;

  function getServicesItem(item: string, index: number) {
    return (
      <ServicesItem key={index}>
        <ServicesItemNumber>{index + 1}</ServicesItemNumber>
        <ServicesItemText>{item}</ServicesItemText>
      </ServicesItem>
    );
  }

  if (!pageFields) return null;

  return (
    <Section>
      <ContentContainer>
        <Inner>
          <Content>
            <Designation>{pageFields.servicesName}</Designation>
            <StyledTitle>{pageFields.servicesTitle}</StyledTitle>
            <ServicesWrapper>
              <ServicesInfo>
                <ServicesInfoInnerWrapper>
                  <ServicesInfoTitle>
                    {pageFields.servicesListTitle}
                  </ServicesInfoTitle>
                  <ServicesList>
                    {splitStringOnSpace(pageFields.servicesListItems)?.map(
                      getServicesItem
                    )}
                  </ServicesList>
                </ServicesInfoInnerWrapper>
              </ServicesInfo>
              {pageFields.servicesImage ? <PictureColumn>
                <PictureWrapper>
                  <StyledPicture
                    tabletSmall={convertThumbnailToPictureImage(
                      pageFields.servicesImage.desktop
                    )}
                    mobileSmall={convertThumbnailToPictureImage(
                      pageFields.servicesImage.mobile
                    )}
                  />
                </PictureWrapper>
              </PictureColumn> : null}
            </ServicesWrapper>
          </Content>
        </Inner>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  padding: 125px 0 111px;
  background: ${colors.white};
  overflow: hidden;

  ${media.laptopLarge(css`
    padding-bottom: 120px;
  `)}

  ${media.tabletSmall(css`
    padding-top: 98px;
    padding-bottom: 90px;
  `)}

  ${media.mobile(css`
    padding-top: 60px;
    padding-bottom: 0;
  `)}
`;

const Inner = styled.div``;

const Content = styled.div``;

const StyledTitle = styled(Title)`
  max-width: 993px;
  width: 100%;
`;

const ServicesWrapper = styled.div`
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  min-height: 522px;

  ${media.laptopLarge(css`
    min-height: 100%;
    max-height: 448px;

    ${createMediaMixin({ max: 1376 })(css`
      max-height: initial;
    `)}
  `)}
  ${media.tabletSmall(css`
    flex-direction: column;
    min-height: initial;
  `)}
`;

const PictureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @media (max-width: 1440px) {
    right: -120px;
  }

  ${media.tabletSmall(css`
    position: initial;
    margin-top: 57px;
  `)}

  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const PictureColumn = styled.div`
  position: relative;
  min-width: 0px;
  flex: 0 0 49.51%;
  padding: 0 30px;
  min-height: 522px;

  ${media.laptopLarge(css`
    min-height: initial;
  `)}

  ${media.tabletSmall(css`
    padding: 0;
    margin: 0 -20px;
    min-height: initial;
  `)}
`;

const StyledPicture = styled(Picture)`
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: initial;
    height: 100%;

    ${media.tabletSmall(css`
      max-height: 450px;
      object-fit: cover;
    `)}

    ${media.mobile(css`
      max-height: initial;
      object-fit: initial;
      height: initial;
      margin: 60px 0;
    `)}
  }

  ${media.laptopLarge(css`
    height: auto;
  `)}

  ${createMediaMixin({ max: 1376 })(css`
    height: 100%;
  `)}
`;

const ServicesInfo = styled.div`
  flex: 0 0 50.5%;
  padding: 0 60px 0 0;

  min-width: 0px;

  ${media.laptopLarge(css`
    padding: 0px 99px 0 0;
  `)}

  ${createMediaMixin({ max: 1376 })(css`
    padding-right: 30px;
  `)}


  ${media.tabletSmall(css`
    padding: 0;
  `)}
`;

const ServicesInfoInnerWrapper = styled.div`
  padding: 112px 50px 122px;
  background: ${colors.gray100};

  ${media.laptopLarge(css`
    padding-top: 63px;
    padding-bottom: 60px;
  `)}

  ${media.tabletSmall(css`
    padding: 66px 50px 70px; ;
  `)}

  ${media.mobile(css`
    padding: 70px 20px;
  `)}
`;

const ServicesInfoTitle = styled.div`
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;

const ServicesList = styled.ul`
  margin-top: 44px;
`;
const ServicesItem = styled.li`
  position: relative;
  margin-top: 33px;

  display: flex;

  &:first-child {
    margin-top: 0;
  }

  ${media.laptopLarge(css`
    padding-right: 35px;
    margin-top: 22px;
  `)}

  ${media.tabletSmall(css`
    max-width: 505px;
  `)}

  ${media.mobile(css`
    max-width: 100%;
    margin-top: 20px;
    padding-right: 0;
  `)}
`;
const ServicesItemText = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
`;

const ServicesItemNumber = styled.div`
  min-width: 22px;
  margin-right: 17px;
  font-weight: 600;
  font-size: 36px;
`;

export default Services;
