import React from 'react';
import styled, { css } from 'styled-components';

import { SmallContentContainer } from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FeedBackSectionSecondType } from '@/typings/model';
import { FeedBackColor } from '@/components/FeebBack';
import { media } from '@/utils/mixin';

function FeedBackSecond() {
  const page = useCurrentPage<FeedBackSectionSecondType>();
  const pageFields = page?.templateFields;

  if (!pageFields) return null;

  return (
    <Section>
      <SmallContentContainer>
        <FeedBackColor
          center={true}
          title={pageFields.ctaSecondTitle}
          buttonTitle={pageFields.ctaSecondButtonLabel}
        />
      </SmallContentContainer>
    </Section>
  );
}

const Section = styled.section`
  & .feedback-color-title {
    text-align: center;
  }

  & .feedback-color-component {
    padding: 39px 0;
  }

  & .feedback-color-btn {
    margin: 20px 0;
  }

  width: 100%;
  background: linear-gradient(
    94.4deg,
    #ec6a81 -4.88%,
    #964589 47.81%,
    #4856d8 102.74%
  );

  ${media.mobile(css`
    margin-top: 0;

    & .feedback-color-wrapper {
      align-items: center;
    }
  `)}
`;

export default FeedBackSecond;
