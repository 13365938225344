import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

interface Props {
  title?: string;
  items: Array<ThumbnailType>;
}

function LogoGallery({ title, items }: Props) {
  return (
    <Component>
      <Title>{title}</Title>
      <List>
        {items.map((client, index) => (
          <Item className="logo-item" key={index}>
            <Picture
              srcWebp={client.url_webp ?? undefined}
              {...convertThumbnailToPictureImage(client)}
            />
          </Item>
        ))}
      </List>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;

const Title = styled.span`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
    line-height: 130%;
  `)}
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-top: 44px;

    ${media.mobile(css`
      margin-top: 20px;
    `)}
  }

  ${media.mobile(css`
    flex-wrap: wrap;
    margin: 0;
  `)}
`;

const Item = styled.div`
  flex: 1 1 13%;

  img {
    max-width: 130px;
    max-height: 47px;

    ${media.tabletSmall(css`
      max-width: 77px;
    `)}
  }

  ${media.tabletSmall(css`
    padding: 0 10px;
    flex: 1 1 20%;
    max-width: 13%;
  `)}

  ${media.mobile(css`
    flex: 1 1 25%;
    max-width: 21%;

    img {
      max-width: 100%;
      max-height: 25px;
    }
  `)}
`;

export default LogoGallery;
