import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';

import ContentContainer from '@/components/ContentContainer';
import { StringFieldType } from '@/typings/common';
import { BaseImageType } from '@/typings/model';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import useHeader from '@/hooks/useHeader';

interface Props {
  children: React.ReactNode;
  backgroundImage?: BaseImageType;
  backgroundImageMobile?: ThumbnailType;
  videoSource?: { url: StringFieldType };
}

function Hero({
  children,
  videoSource,
  backgroundImage,
  backgroundImageMobile,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [, setState] = useHeader();
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (ref.current) {
        if (window.pageYOffset > ref.current.clientHeight - 70) {
          setState(true);
        } else {
          setState(false);
        }
      }
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Component ref={ref} id="hero-component">
      <StyledContentContainer>
        <InnerWrapper>
          <Content>{children}</Content>
        </InnerWrapper>
      </StyledContentContainer>
      {videoSource && videoSource.url ? (
        <HeroVideo
          preload={videoSource.url}
          width="100%"
          height="auto"
          autoPlay
          loop
          muted
          playsInline
          onCanPlay={() => {
            setCanPlay(true);
          }}
        >
          <source src={videoSource.url} type="video/mp4" />
        </HeroVideo>
      ) : null}

      {backgroundImage ? (
        <BackgroundPicture
          placeholderColor={`rgba(0, 0, 0, 0.4)`}
          desktop={convertThumbnailToPictureImage(backgroundImage.desktop)}
          laptop={convertThumbnailToPictureImage(backgroundImage.laptop)}
          tabletSmall={convertThumbnailToPictureImage(backgroundImage.tablet)}
          mobileSmall={convertThumbnailToPictureImage(backgroundImageMobile)}
        />
      ) : null}
      <Overlay isTransparent={canPlay} />
    </Component>
  );
}

const Component = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 720px;
  padding-top: 190px;
  padding-bottom: 50px;
  z-index: 1;

  ${media.tabletSmall(css`
    padding-top: 159px;
  `)}

  ${media.mobile(css`
    padding-top: 130px;
  `)}
`;

const Overlay = styled.div<{ isTransparent: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.8s linear;
  background-color: ${(props) =>
    props.isTransparent ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.8)'};
  z-index: 9;

  ${media.mobile(css`
    background-color: transparent !important;
  `)}
`;

const StyledContentContainer = styled(ContentContainer)`
  height: 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  ${media.tabletLarge(css`
    justify-content: flex-start;
  `)}
`;

const BackgroundPicture = styled(Picture)`
  position: initial !important;
  z-index: 1;

  & > div {
    position: initial !important;
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  ${media.mobile(css`
    display: initial !important;
  `)}
`;

const HeroVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  object-fit: cover;

  ${media.mobile(css`
    display: none;
  `)}
`;

const Content = styled.div`
  position: relative;
  z-index: 10;
`;

export default Hero;
