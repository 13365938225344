import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { AccordionItemType, SystemCdpPageType } from '@/typings/model';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { getHtmlString } from '@/utils/common';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-down.svg';
import { media } from '@/utils/mixin';

interface AccordionBaseItem extends AccordionItemType {
  maxHeight: string;
  isActive: boolean;
  id: number;
  ref: React.RefObject<HTMLParagraphElement>;
}

function getBaseAccordionList(
  items: Array<AccordionItemType>
): Array<AccordionBaseItem> {
  return items.map((item, index) => {
    if (index === 0) {
      return {
        ...item,
        maxHeight: 'initial',
        isActive: true,
        id: index,
        ref: React.createRef(),
      };
    } else {
      return {
        ...item,
        maxHeight: '0',
        isActive: false,
        id: index,
        ref: React.createRef(),
      };
    }
  });
}

function Accordion({ items }: { items: Array<AccordionItemType> }) {
  const [state, setState] = useState<Array<AccordionBaseItem>>(
    getBaseAccordionList(items)
  );

  return (
    <AccordionInner>
      <AccordionImageWrapper>
        {state.map((item, index) => {
          return (
            <PictureColumn key={index} isActive={item.isActive}>
              {item.image ? <PictureWrapper>
                <StyledPicture
                  tabletSmall={convertThumbnailToPictureImage(
                    item.image.desktop
                  )}
                  mobileSmall={convertThumbnailToPictureImage(
                    item.image.mobile
                  )}
                />
              </PictureWrapper> : null}
            </PictureColumn>
          );
        })}
      </AccordionImageWrapper>
      <AccordionInfo>
        <AccordionList>
          {state.map((item, index) => {
            return (
              <AccordionItem
                key={index}
                isActive={item.isActive}
                onClick={() => {
                  const newState = state.map((item) => {
                    if (item.id === index) {
                      const activeRef = item.ref.current;
                      const currentHeight = activeRef?.scrollHeight ?? 0;
                      return {
                        ...item,
                        maxHeight: `${currentHeight}px`,
                        isActive: true,
                      };
                    } else {
                      return {
                        ...item,
                        maxHeight: '0',
                        isActive: false,
                      };
                    }
                  });
                  setState(newState);
                }}
              >
                <ArrowIcon />
                <AccordionTitle>{item.title}</AccordionTitle>
                <MobilePicture
                  isActive={item.isActive}
                  mobileSmall={convertThumbnailToPictureImage(
                    item.image.mobile
                  )}
                />
                <AccordionText ref={item.ref} maxHeight={item.maxHeight}>
                  {item.text}
                </AccordionText>
              </AccordionItem>
            );
          })}
        </AccordionList>
      </AccordionInfo>
    </AccordionInner>
  );
}

const StyledPicture = styled(Picture)`
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: initial;
    height: 100%;

    ${media.mobile(css`
      width: 100%;
      height: initial;
    `)}
  }
`;

const MobilePicture = styled(Picture)<{ isActive: boolean }>`
  display: none !important;
  ${({ isActive }) =>
    isActive
      ? css`
          ${media.tabletSmall(css`
            display: initial !important;
          `)}
        `
      : css`
          ${media.tabletSmall(css`
            display: none !important;
          `)}
        `}

  ${media.tabletSmall(css`
    img {
      width: 92%;
      margin-top: 40px;
    }
  `)}

  ${media.mobile(css`
    img {
      width: initial;
    }
  `)}
`;

const PictureColumn = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  position: relative;
  min-width: 0px;
  flex: 0 0 50%;
  padding: 0 50px;
  justify-content: flex-end;

  ${media.tabletSmall(css`
    display: none;
    padding: 0;
  `)}
`;

const PictureWrapper = styled.div`
  width: 577px;
  height: 405px;
  border-radius: 4px;
  overflow: hidden;

  img {
    border-radius: 4px;
  }

  @media (max-width: 1440px) {
    margin-left: -90px;
  }

  ${media.mobile(css`
    position: static;
    width: auto;
    height: auto;
  `)}
`;

function System() {
  const page = useCurrentPage<SystemCdpPageType>();
  const pageFields = page?.templateFields;

  if (!pageFields) return null;

  return (
    <Section>
      <ContentContainer>
        <Inner>
          <Content>
            <Scheme>
              <SchemeTitle
                dangerouslySetInnerHTML={{
                  __html: getHtmlString(pageFields.accordTitle ?? ''),
                }}
              />
              <SchemeImageWrapper>
                <Picture
                  mobileSmall={convertThumbnailToPictureImage(
                    pageFields.accordImage.mobile
                  )}
                  tabletSmall={convertThumbnailToPictureImage(
                    pageFields.accordImage.desktop
                  )}
                />
              </SchemeImageWrapper>
            </Scheme>
            {pageFields.accordItems.length > 0 ? (
              <AccordionBlock>
                <Accordion items={pageFields.accordItems} />
              </AccordionBlock>
            ) : null}
          </Content>
        </Inner>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  padding: 120px 0 98px;
  background: ${colors.dark};
  overflow: hidden;

  ${media.tabletSmall(css`
    padding-bottom: 43px;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Inner = styled.div``;
const Content = styled.div``;
const Scheme = styled.div``;

const SchemeTitle = styled.span`
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  display: block;
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
  text-align: center;
  color: ${colors.white};

  & > span {
    color: rgb(150, 69, 137) !important;
  }

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
    line-height: 130%;
  `)}
`;
const SchemeImageWrapper = styled.div`
  margin-top: 40px;

  & > div {
    ${media.tabletSmall(css`
      left: -15px;
    `)}

    ${media.mobile(css`
      left: -5px !important;
    `)}
  }
`;
const AccordionInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -30px;

  ${media.tabletSmall(css`
    margin: 0;
    display: initial;
  `)}
`;
const AccordionBlock = styled.div`
  margin-top: 125px;
  ${media.tabletSmall(css`
    margin-top: 63px;
  `)}
`;
const AccordionInfo = styled.div`
  flex: 0 0 50%;

  ${media.laptopLarge(css`
    flex: 0 0 58%;
  `)}

  ${media.laptop(css`
    flex: 1 1 100%;
    padding-right: 40px;
  `)}


  ${media.mobile(css`
    flex: 0 0 100%;
    padding-right: 0;
  `)}
`;
const AccordionImageWrapper = styled.div`
  flex: 0 0 50%;

  ${media.laptopLarge(css`
    margin-left: -94px;
  `)}
`;

const AccordionList = styled.ul``;
const AccordionItem = styled.li<{ isActive: boolean }>`
  position: relative;
  padding-left: 35px;
  color: ${({ isActive }) => (isActive ? colors.white : colors.pink)};

  &:first-child {
    margin-top: 0 !important;
  }

  margin-top: 43px;

  svg {
    position: absolute;
    left: 3px;
    top: 14px;
    transition: transform 250ms ease-in;
    transform: rotate(${({ isActive }) => (isActive ? '180deg' : '0deg')});

    ${media.mobile(css`
      top: 7px;
    `)}
  }

  svg path {
    stroke: ${({ isActive }) => (isActive ? colors.white : colors.pink)};
  }

  cursor: pointer;
`;
const AccordionText = styled.p<{ maxHeight: string }>`
  max-width: 656px;
  width: 100%;
  margin-top: ${({ maxHeight }) =>
    maxHeight === '0' ? '0' : '24px !important'};
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  transition: all 0.4s ease-in-out;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  cursor: initial;

  ${({ maxHeight }) =>
    maxHeight === '0'
      ? css`
          margin: 0;

          ${media.tabletSmall(css`
            margin: 0;
            font-size: 20px;
          `)}

          ${media.mobile(css`
            margin: 0;
            font-size: 18px;
          `)}
        `
      : css`
          margin-top: 24px;

          ${media.tabletSmall(css`
            font-size: 20px;
            margin-top: 25px;
          `)}

          ${media.mobile(css`
            font-size: 18px;
          `)}
        `}
`;
const AccordionTitle = styled.span`
  display: block;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;

  ${media.tabletSmall(css`
    font-size: 26px;
    //margin-bottom: 25px;
  `)}

  ${media.mobile(css`
    //margin-bottom: 40px;
    font-size: 22px;
    line-height: 130%;
  `)}
`;

export default System;
