import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const Designation = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
`;
export const Title = styled.span`
  max-width: 840px;
  width: 100%;
  margin-top: 22px;
  display: block;
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;

  ${media.tabletSmall(css`
    font-size: 32px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
    line-height: 130%;
  `)}
`;
