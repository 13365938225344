import React from 'react';
import styled from 'styled-components';

import {
  convertThumbnailToPictureImage,
  convertThumbnailToPictureProps,
} from '@tager/web-modules';

import useCurrentPage from '@/hooks/useCurrentPage';
import { HeroCdpPageType } from '@/typings/model';
import Hero, { HeroTitle, HeroText, HeroButton } from '@/components/Hero';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import QuestionSection from '@/modules/CustomDataPlatform/components/QuestionSection';
import DescriptionOfTechnology from '@/modules/CustomDataPlatform/components/DescriptionOfTechnology';
import KeyCases from '@/modules/CustomDataPlatform/components/KeyCases';
import Clients from '@/modules/CustomDataPlatform/components/Clients';
import System from '@/modules/CustomDataPlatform/components/System';
import WorkRules from '@/modules/CustomDataPlatform/components/WorkRules';
import Resources from '@/modules/CustomDataPlatform/components/Resources';
import Integrations from '@/modules/CustomDataPlatform/components/Integrations';
import FeedBack from '@/modules/CustomDataPlatform/components/FeedBack';
import useForm from '@/hooks/useForm';
import useAnimation from '@/hooks/useAnimation';

import { InnerMainContent } from '../common.styles';

function CustomDataPlatform() {
  const page = useCurrentPage<HeroCdpPageType>();
  const [openForm] = useForm();
  const pageFields = page?.templateFields;

  useAnimation({
    animationElement: 'inner-main-content',
    targetElement: 'hero-component',
  });

  if (!pageFields) return null;

  return (
    <Wrapper>
      <Hero
        backgroundImage={pageFields.heroImageBackground}
        backgroundImageMobile={pageFields.heroImageBackgroundMobile}
        videoSource={pageFields.heroVideoBackground}
      >
        <WrapperContent>
          <LogoWrapper>
            <LogoPicture
              {...convertThumbnailToPictureImage(pageFields.heroLogo)}
            />
          </LogoWrapper>
          <HeroTitle color={colors.white}>{pageFields.heroTitle}</HeroTitle>
          <HeroText color={colors.white}>{pageFields.heroText}</HeroText>
          <HeroButton onClick={openForm}>
            {pageFields.heroButtonLabel}
          </HeroButton>
        </WrapperContent>
      </Hero>
      <InnerMainContent id="inner-main-content">
        <QuestionSection />
        <DescriptionOfTechnology />
        <KeyCases />
        <Clients />
        <System />
        <WorkRules />
        <Resources />
        <Integrations />
        <FeedBack />
      </InnerMainContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WrapperContent = styled.div`
  max-width: 997px;
  width: 100%;
`;

const LogoPicture = styled(Picture)``;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  min-height: 50px;
`;

export default CustomDataPlatform;
