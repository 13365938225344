import React from 'react';

import { Page } from '@tager/web-components';

import Layout from '@/components/Layout';
import ContentContainer from '@/components/ContentContainer';
import HeaderProvider from '@/provider/HeaderProvider';
import FormProvider from '@/provider/FormProvider';
import NotFound from '@/modules/NotFound';

function NotFoundPage() {
  return (
    <Page title="Страница не найдена">
      <FormProvider>
        <HeaderProvider>
          <Layout>
            <ContentContainer>
              <NotFound />
            </ContentContainer>
          </Layout>
        </HeaderProvider>
      </FormProvider>
    </Page>
  );
}

export default NotFoundPage;
