import React, { useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';
import StyledButton from '@/components/StyledButton';
import useHeader from '@/hooks/useHeader';
import { media } from '@/utils/mixin';

function NotFound() {
  const [, setColorHeader] = useHeader();
  const title = useSettingItem('NOT_FOUND_TITLE');
  const text = useSettingItem('NOT_FOUND_TEXT');
  const buttonTitle = useSettingItem('NOT_FOUND_BUTTON');
  const router = useRouter();

  useLayoutEffect(() => {
    setColorHeader(true);

    return () => {
      setColorHeader(false);
    };
  }, []);

  return (
    <Content>
      <Title>{title ?? 'Страница не найдена'}</Title>

      <Text
        dangerouslySetInnerHTML={{
          __html: text ?? '',
        }}
      />
      <Button onClick={() => router.push('/')}>
        {buttonTitle ?? 'О компании'}
      </Button>
    </Content>
  );
}

const Title = styled.span`
  font-weight: 600;
  font-size: 60px;
  line-height: 110%;
  color: ${colors.dark};
  ${media.mobile(css`
    font-size: 36px;
  `)}
`;

const Content = styled.div`
  padding: 190px 0;
`;

const Text = styled.span`
  display: block;
  margin-top: 20px;
  font-size: 20px;
  line-height: 150%;
  color: ${colors.dark};
`;

const Button = styled(StyledButton)`
  margin-top: 58px;
`;

export default NotFound;
