import { useLayoutEffect } from 'react';

function useAnimation(options?: {
  targetElement?: string;
  animationElement?: string;
}) {
  useLayoutEffect(() => {
    if (options && options.targetElement && options.animationElement) {
      const targetElement = document.getElementById(options.targetElement);
      const animationElement = document.getElementById(
        options.animationElement
      );

      if (targetElement && animationElement) {
        const heightTargetElement =
          targetElement.getBoundingClientRect().height ?? 0;
        animationElement.style.marginTop = `${heightTargetElement}px`;
      }
    }
  }, []);
}

export default useAnimation;
