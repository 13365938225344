import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useUpdateEffect } from '@tager/web-core';

import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import useHeader from '@/hooks/useHeader';
import { colors } from '@/constants/theme';
import { PrivacyPolicePageType, SectionAccordionItem } from '@/typings/model';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-down.svg';

function AccordionItem({
  defaultState = false,
  title,
  index,
  text,
}: {
  defaultState: boolean;
  title: string;
  text: string;
  index: number;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState(false);
  const [currentHeight, setCurrentHeight] = useState<string>(
    defaultState ? 'initial' : '0'
  );

  useLayoutEffect(() => {
    if (index === 0) {
      setState(!state);
    }
  }, []);

  useUpdateEffect(() => {
    const element = ref.current;
    if (element) {
      const height = element.scrollHeight ?? 0;
      if (state) {
        setCurrentHeight(`${height}px`);
      } else {
        setCurrentHeight('0');
      }
    }
  }, [state]);

  return (
    <SideBarAccordionItem
      ref={titleRef}
      isActive={state}
      onClick={() => {
        if (titleRef.current) {
          const offsetTop = titleRef.current.offsetTop;
          setState(!state);
          if (!state) {
            setTimeout(() => {
              if (titleRef.current) {
                window.scroll({
                  top: offsetTop - 70,
                  behavior: 'smooth',
                });
              }
            }, 10);
          }
        }
      }}
    >
      <SideBarAccordionTitle isActive={state}>
        <SideBarAccordionTitleWrapper>{title}</SideBarAccordionTitleWrapper>
        <ArrowIcon />
      </SideBarAccordionTitle>
      <WrapperText isActive={state} maxHeight={currentHeight} ref={ref}>
        <InformationItemTitle
          style={{
            padding: '0 20px',
            margin: '20px 0 0 0',
            color: colors.dark,
          }}
        >
          {index + 1}. {title}
        </InformationItemTitle>
        <SideBarAccordionText
          dangerouslySetInnerHTML={{ __html: text ?? '' }}
        />
      </WrapperText>
    </SideBarAccordionItem>
  );
}

function Accordion({ items }: { items: Array<SectionAccordionItem> }) {
  return (
    <SideBarAccordion>
      <SideBarAccordionContent>
        {items.map((item, index) => (
          <AccordionItem
            title={item.title ?? ''}
            text={item.content ?? ''}
            defaultState={index === 0}
            index={index}
            key={index}
          />
        ))}
      </SideBarAccordionContent>
    </SideBarAccordion>
  );
}

const WrapperText = styled.span<{ maxHeight: string; isActive: boolean }>`
  display: block;
  overflow: hidden;
  max-height: ${({ maxHeight }) => maxHeight};
  margin: ${({ isActive }) => (isActive ? '20px 0' : '0')};
  //transition: all 1.5s ease;
`;

interface BaseSections extends SectionAccordionItem {
  ref: React.RefObject<HTMLDivElement>;
}

function getBaseSections(sections: SectionAccordionItem[]): BaseSections[] {
  return sections.map((section) => ({
    ...section,
    ref: React.createRef(),
  }));
}

function PrivacyPolicy() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const page = useCurrentPage<PrivacyPolicePageType>();
  const pageFields = page?.templateFields;
  const [, changeColor] = useHeader();
  const baseSections = getBaseSections(pageFields?.sections ?? []);

  function scrollToSection(id: string, index: number) {
    return () => {
      const section = document.getElementById(id);
      if (section) {
        window.scroll({
          top: section.offsetTop - 110,
          behavior: 'smooth',
        });
      }
      setCurrentIndex(index);
    };
  }

  useEffect(() => {
    const coordinateSectionsItems = baseSections.map((section) => {
      if (section.ref.current) {
        return section.ref.current.offsetTop;
      } else {
        return 0;
      }
    });

    function onScroll() {
      for (let i = 0; i < coordinateSectionsItems.length; i++) {
        if (window.pageYOffset > coordinateSectionsItems[i] - 140) {
          setCurrentIndex(i);
        }
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    changeColor(true);
    return () => {
      changeColor(false);
    };
  }, []);

  if (!pageFields) return null;

  return (
    <Section>
      <StyledContainer>
        <Content>
          <Title>{page?.title}</Title>
          <InformationSection>
            <InformationContent>
              {baseSections
                ? baseSections.map((item, index) => {
                    const id = `${item.title}${index}`;
                    return (
                      <InformationItem ref={item.ref} key={index} id={id}>
                        <InformationItemTitle>
                          {index + 1}
                          {'. '}
                          {item.title}
                        </InformationItemTitle>
                        <InformationItemContent
                          dangerouslySetInnerHTML={{
                            __html: item.content ?? '',
                          }}
                        />
                      </InformationItem>
                    );
                  })
                : null}
            </InformationContent>
            <Sidebar>
              <SideBarInner>
                <SideBarWrapper>
                  <SideBarList>
                    {pageFields.sections
                      ? pageFields.sections.map((item, index) => (
                          <SideBarItem
                            isActive={currentIndex === index}
                            onClick={scrollToSection(
                              `${item.title}${index}`,
                              index
                            )}
                            key={index}
                          >
                            <SideBarItemIndex>{index + 1}.</SideBarItemIndex>
                            <SideBarTitle>{item.sidebarTitle}</SideBarTitle>
                          </SideBarItem>
                        ))
                      : null}
                  </SideBarList>
                </SideBarWrapper>
              </SideBarInner>
            </Sidebar>
          </InformationSection>
        </Content>
      </StyledContainer>
      <Accordion items={pageFields.sections} />
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  padding: 130px 0;
  overflow: initial !important;

  ${media.mobile(css`
    padding: 110px 0 60px;
  `)}
`;

const StyledContainer = styled(ContentContainer)`
  ${media.tabletSmall(css`
    padding: 0 20px;
  `)}
`;

const Content = styled.div``;

const Title = styled.h1`
  margin: 0;
  max-width: 805px;
  width: 100%;
  font-weight: 600;
  font-size: 60px;
  line-height: 110%;
  color: ${colors.dark};

  ${media.mobile(css`
    font-size: 26px;
    line-height: 130%;
  `)}
`;

const InformationSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: initial;

  ${media.mobile(css`
    display: none;
    z-index: -1;
  `)}
`;

const InformationContent = styled.div`
  width: 70%;
  margin-top: 15px;
  padding-right: 36px;

  p {
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
  }
`;

const InformationItem = styled.div``;

const InformationItemTitle = styled.span`
  display: block;
  margin-top: 54px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 30px;

  &:first-child {
    margin-top: 40px;
  }
`;
const InformationItemContent = styled.span`
  p {
    margin-top: 31px;
  }
`;

const Sidebar = styled.div`
  width: 28.5%;
`;

const SideBarInner = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const SideBarWrapper = styled.div`
  padding-top: 97px;
  margin-top: -53px;
`;

const SideBarList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 7px 26px 30px;
  background: #f3f8f9;
`;
const SideBarItem = styled.li<{ isActive: boolean }>`
  position: relative;
  margin-top: 19px;
  cursor: pointer;
  padding-left: 30px;
  color: ${({ isActive }) => (isActive ? colors.pink : colors.dark)};

  &:first-child {
    margin-top: 28px;
  }
`;
const SideBarTitle = styled.span`
  font-size: 20px;
  line-height: 150%;
`;

const SideBarItemIndex = styled(SideBarTitle)`
  position: absolute;
  top: 0;
  left: 0;
`;

const SideBarAccordion = styled.div`
  display: none;
  z-index: -1;

  ${media.mobile(css`
    margin-top: 20px;
    display: block;
    z-index: 1;
  `)}
`;

const SideBarAccordionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SideBarAccordionItem = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? colors.pink : colors.dark)};

  ${media.mobile(css`
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  `)}
`;

const SideBarAccordionTitle = styled.span<{ isActive: boolean }>`
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 150%;
  width: 100%;
  padding: 10px 20px;
  z-index: 10;
  background: #f3f8f9;

  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transition: transform 250ms ease-in;
    transform: translateY(-50%)
      rotate(${({ isActive }) => (isActive ? '180deg' : '0deg')});
    z-index: 10;
  }

  svg path {
    stroke: ${({ isActive }) => (isActive ? colors.pink : colors.dark)};
  }
`;

const SideBarAccordionTitleWrapper = styled.div`
  position: relative;
  max-width: 280px;
  width: 100%;
`;

const SideBarAccordionText = styled.span`
  display: block;

  color: ${colors.dark};
  padding: 0 20px;

  p {
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export default PrivacyPolicy;
